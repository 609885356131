import React from "react";
import "./404.css";
import Lars from "../Home/HeroHeader/avataaars.svg";
function fourOhFour() {
  return (
    <div className="page">
      <div className="row">
        <a href="/">
          <img src={Lars} height="150px" alt="Lars' head" />
        </a>
      </div>
      <section className="section wrapper">
      <h2>nothing here except for this giraffe.</h2>
      <p>try going back <a href="/">home</a>.</p>
      <div className="row">
        <div className="column-10">
          <video className="video-404" autoplay muted controls src="https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/video.mp4" type="video/mp4">
          </video>
        </div>
      </div>
      </section>
 
    </div>
  );
}
export default fourOhFour;
