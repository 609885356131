import { useState, useEffect } from "react";
import { data } from "./data";
import "./projects.css";
import ProjectDetails from "./ProjectDetail";


function Projects() {
  const [projects] = data();
  const [access, setAccess] = useState("");
  const [visibility, showPortfolio] = useState(false);
  const [errorMessage, setMessage] = useState("");


  const setTokens = (token) => {
    window.localStorage.setItem("password", token);
  };

  useEffect(() => {
    checkStorage();
  }, []);

  const inputPasscode = (event) => {
    event.preventDefault();
    let passcode = event.target.value;
    setAccess(passcode);
  };

  //low security portfolio protection
  const checkStorage = () => {
    const accessCode = window.localStorage.getItem("password");
    if (accessCode) {
      setAccess(accessCode);
      if (accessCode === "hi_lars") {
        showPortfolio(true);
      }
    } else {
      setAccess("");
    }
  };

  const checkAccess = () => {
    if (access === "hi_lars") {
      showPortfolio(true);
      setTokens(access);
    } else {
      setMessage("Incorrect password. Please try again.");
      setAccess("");
    }
  };

  // just for testing
  // const reset = () => {
  //   setAccess("");
  //   showPortfolio(false);
  //   setTokens("")
  // }
  return (
    <div>
        <div>
        <code>
            <h2>Portfolio</h2>
          </code>
          {/* just for testing */}
    {/* <button onClick={()=>reset()}>reset</button> */}
          {!visibility && (
            <p>
              This portfolio is password-protected. Please{" "}
              <a href='mailto:larsmackenzie@gmail.com?subject=Portfolio access request'>reach out</a> if you'd
              like access.
            </p>
          )}
        </div>

        {!visibility && (
          <div className="modal-container row center">
            <div className="modal">
              <h2>Enter password</h2>
              <p>{errorMessage}</p>
              <div className="row center">
                <input
                  type="password"
                  value={access}
                  className="password"
                  onChange={inputPasscode}
                />
                <button onClick={() => checkAccess()}>submit</button>
              </div>
            </div>
          </div>
        )}
     
        {visibility && (
          <div>
            <div className="bio-container">
            <p>
              This portfolio represents a sample of some of the types of work I
              do and a bit into my process. You'll find some of the
              public-facing projects I've worked on, including some front-end
              development, UX design and research, and learning development.
            </p>
            <p>
              If you are interested in seeing other projects or talking further
              about my design, development, and research methodologies, please{" "}
              <a href="mailto:larsmackenzie@gmail.com?subject=I want to learn more!">reach out!</a>
            </p>
            </div>
         
            <div className="column-lg-12 column-md-12 row center">
              {projects.map((item, i) => {
                return <ProjectDetails project={item} key={i} />;
              })}
            </div>
          </div>
        )}
    
    </div>
  );
}
export default Projects;
