import React from "react";
import './styles.css';

import Lars from '../Home/HeroHeader/avataaars.svg';



function SimpleHeader() {

  return (
    <div className="row">
    <a href="/">
      <img src={Lars} height="150px" alt="Lars' head"/>
    </a>
  </div>
  );
}
export default SimpleHeader;
