import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { data } from "./data";
import "./projects.css";
import SimpleHeader from '../../SimpleHeader/SimpleHeader';
import ReactMarkdown from 'react-markdown';

function ProjectsPages() {
  const [projects] = data();
  const [project, setProject] = useState({});

  // get the slug of the URL we entered
  let { slug } = useParams();

  // then match it with our data.
  useEffect(() => {
    let project = projects.filter((project) => project.slug === slug);
    setProject(project);
  }, [projects, slug]);



  return (
    <div>
      <SimpleHeader/>

      <button className="space"><a href="/#portfolio">&larr; back to portfolio</a></button>
      <div className=" column-12">
        {project[0] !== undefined && (
          <div className="loose-page">
            <div className="project-billboard">
              <div>
                <h2>{project[0].title}</h2>
              </div>
              
              <div className="details wrapper">
                <div className="row">
                  {project[0].skills.map((skill, i) => {
                    return (
                      <div className="skill" key={i}>
                        {skill}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="project-section">
                <h3>Background</h3>
                <div className="column center">
                  {project[0].background.text.map((text, i) => {
                    return (
                      <div key={i} className="column-lg-10 column-md-10">
                        <ReactMarkdown>{text}</ReactMarkdown>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="project-section">
                <h3>Deliverable</h3>

                <div className="column center">
                  {project[0].solution.text.map((text, i) => {
                    return (
                      <div key={i} className="column-lg-10 column-md-10">
                        <ReactMarkdown>{text}</ReactMarkdown>
                      </div>
                    );
                  })}
              
                </div>
              </div>
              <div className="row center">
           
                {project[0].solution.example.length > 0 && (
                  <div
                    className="column-12 column-md-12"
                    id="project-container">
                    {project[0].solution.example.map((example, i) => {
                      if (example.type === "iframe") {
                        return (
                          <div key={i} className="project-section">
                            <h4 className="example-title">{example.title}</h4>
                            <div className="video-container">
                              <iframe
                                src={example.url}
                                id={example.slug}
                                allowFullScreen
                                title={`${project[0].title} solution example`}
                              ></iframe>
                            </div>
                            <p className="text-right">open in <a href={example.url} target="_blank" rel="noopener noreferrer" className="highlight">fullscreen</a></p>
                          </div>
                        );
                      } else if (example.type === "image") {
                        return (
                          <div className="project-section">
                            
                            <div className="row center">
                              <img
                                src={example.url}
                                alt={example.alt}
                                className="featured-image"
                              />
                            </div>
                          </div>
                        );
                      } return (<div></div>)
                    })}
                  </div>
                )}
                   {project[0].url.length > 0 && (
                    <p className="prominent">
                      View{" "}
                      <a
                        href={project[0].url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="highlight"
                        
                      >
                        the project &rarr;
                      </a>
                    </p>
                  )}
              </div>
              
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ProjectsPages;
