import React from 'react';
import HeroHeader from '../Home/HeroHeader/HeroHeader';
function FakeOut ( ) {
    return(
<div>
        <HeroHeader/>
</div>
    )

}
export default FakeOut;