// theme.js
export const lightTheme = {
    name: 'light',
    body: 'rgb(242, 242, 242)',
    text: '#363537',
    toggleBorder: '#FFF',
    gradient: 'linear-gradient(#7D80DA, #CEBACF)',
    containerBody: 'rgba(248,248,255, 80%)',
    containerText: '#2D2D2A',
    containerHighlight: '#5BC0BE', 
    containerHighlightText: '#363537',
    link: '#246EB9',
    shadow: '#5BC0BE',
    selection: 'rgba(0, 0, 0, .8)',
    highlight: '#FFE74C',
    secondary: '#D0E37F',
    icon_color: '#363537',
    background_v1: '#f2f2f2',
    background_v2: 'rgba(242,242,242, .3)'
    
  }
  
  export const darkTheme = {
    name: 'dark',
    body: '#363537',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#CEBACF, #7D80DA)',
    containerBody: '#474649',
    containerText: '#EEE5E9',
    containerHighlight: '#EA7AF4',
    containerHighlightText: '#f2f2f2',
    link: '#DAFF7D',
    shadow: '#EA7AF4',
    selection: '#FFF',
    highlight: '#EA7AF4', 
    secondary: '#D0E37F',
    icon_color: 'transparent',
    background_v1: 'rgba(67, 66, 69, .8)',
    background_v2: 'rgba(0, 0, 0, .5)'
    

  }