import React from "react";

function ProjectDetails(props) {
  return (
    <div className="project-container column-lg-5 column-md-5">
      {props.project &&
      <div className="text-container">
        <div className="color-bar"></div>
        <div className="description">
          <h3 className="title-only">{props.project.title}</h3>
          <p>{props.project.short_description}</p>
        </div>

        {/* instead of doing tags, let's pull some icons here to make this look nice */}
        <div className="tag-container row ">
          {props.project.tags.map((tag, i) => {
            return (
              <div key={i}>
                <img className="tag-image" src={tag.image} alt={tag.name} />
              </div>
            );
          })}
        </div>
        <div className="cta-bar">
          <a className="link" href={`/portfolio/${props.project.slug}`}>
            Read more &rarr;
          </a>
        </div>
      </div>}
    </div>
  );
}
export default ProjectDetails;
