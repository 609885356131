import React from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./themes/theme";
import { GlobalStyles } from "./themes/global";
import { useDarkMode } from "./themes/useDarkmode";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NoMatch from './components/404/404';
import Footer from './components/Home/Contact/Contact';
import FakeOut from './components/FakeOut/FakeOut'
import ProjectPages from "./components/Home/Projects/ProjectsPages.js";


function App() {
  const [theme, componentMounted] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  if (!componentMounted) {
    return <div />;
  }

  return (

 <ThemeProvider theme={themeMode}>
      <>
        <GlobalStyles />
        <Router>
     
          <Switch>
            <Route exact path="/" component={FakeOut}/>
            <Route path="/portfolio/:slug" component={ProjectPages} /> 
           
            <NoMatch />
          </Switch>
        </Router>
        <Footer/>
      </>
    </ThemeProvider>

   
  );
}

export default App;
