
export const data = () => {
  let projects = [
    {
      title: `Trauma-Responsive Congregations`,
      slug: "congregations",
      short_description: `Self-paced lessons in spiritual care and ethics.`,
      url: ``,
      background: {
        text: ["Boston University School of Theology’s Center for Practical Theology was awarded a grant to create educational materials around trauma-informed care for urban congregations. Dr. Shelly Rambo, who led the initative, asked me to create five self-paced lessons in Articulate Storyline to distribute to the participating congregations. Dr. Rambo wanted a simple, cohesive design across all of the modules."],
        example:
         [ "https://docs.google.com/presentation/d/e/2PACX-1vTbylMQEvaBFtsUmg7deLKLBumL2NdQUSmpyXcgoM3PisOlUSK9u5xYijMrlurt-A/embed?start=false&loop=false&delayms=3000"],
      },
      solution: {
        text: ["Over the course of a year, I worked with a group of subject matter experts to develop the lessons, going through rounds of iterative feedback and revision. I was responsible for building all of the lessons in Articulate Storyline, making visual design decisions, and providing guidance on written text for all 5 lessons. Each lesson contained over 50 slides, audio and video contributions from subject matter experts, reflections and meaningful interactions.", "I also worked with a translator to create Spanish versions of each lesson."],
        example: [
      
          {
            url: "https://congregations.s3.us-east-2.amazonaws.com/Module4/story.html",
            type: "iframe",
            title: `Preview Module 4: Spiritual Vitality, part 2`,
            slug: "congregations",
          },
        ],
      },
  
      skills: [
        "Articulate Storyline",
        "learning design",
        "stakeholder management",
      ],
      tags: [
        {
          name: "software development",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/coding+(1).svg",
        },
        {
          name: "design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/color-palette.svg",
        },
        {
          name: "video",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/video.svg",
        },
        {
          name: "learning design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/backpack.svg",
        },
      ],
    },
    {
      title: `Online Teaching Toolkit Redesign`,
      slug: "teaching-online",
      short_description: `Turning a cohort-based training to a scalable asychronous course.`,
      url: ``,
      background: {
        text: ["Simmons University previously offered a cohort-based training for faculty who were new to teaching online. When all instruction moved online in March 2020 due to COVID, my team was tasked with designing this training to make it more accessible to a broader group of participants."],
        example:
         [ ""],
      },
      solution: {
        text: ["I collborated with another learning designer and an LMS administrator to create an asynchronous training program for Simmons faculty.","For this project, I created Vyond videos, designed branching exercises, updated assessments, and developed a modern and responsive user interface using CSS and HTML."],
        example: [
      
          {
            url: "https://www.youtube.com/embed/ICqYKDa4Ow4",
            type: "iframe",
            title: "Watch the trailer I developed for this training.",
            slug: "trailer",
          },
        ],
      },
 
      skills: [
        "learning design",
        "collaboration",
        "animation",
        "user interface design",
        "video production",
        "writing",
      ],
      tags: [
        {
          name: "software development",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/coding+(1).svg",
        },
        {
          name: "design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/color-palette.svg",
        },
        {
          name: "design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/video.svg",
        },
        {
          name: "learning design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/backpack.svg",
        },
      ],
    },
    {
      title: `Minnesota Transform`,
      slug: "minnesota-transform",
      short_description: `A website for a public humanities project.`,
      url: `https://minnesotatransform.com`,
      background: {
        text: ["Minnesota Transform is a major higher education initiative engaging anti-colonial and racial justice work through the public humanities at the University of Minnesota, in the Twin Cities, and across the state of Minnesota. The group wanted to showcase the wide range of projects they were working on, but didn't have a great website to do so."],
        example: [],
      },
      solution: {
        text: ["I provided UX design and full-stack development services to the group. Throughout the development process, I facilitated brainstorming meetings to determine an appropriate information architecture for the site and begin to conceptualize the database and content management needs.", "Then, I iteratively developed templated pages in React, leveraging a GraphQL service for our backend and collaborated with interdisciplinary teams to conceptualize design components, craft content, and add functionality to the site. I provide on-going site maintainence and deploy new features as requested."],
        example: [
          {
            url: "https://minnesotatransform.com/static/media/HftF1.fc03f133de3b0f253202.jpg",
            alt: "home page",
            type: "image",
            title: "Minnesota Transform home page"
          },
        ],
      },
      skills: [
        "UX design",
        "front-end development",
        "GraphQL",
        "React",
        "HTML",
        "CSS",
        "project management", 
        "stakeholder management"
      ],
      tags: [
        {
          name: "software development",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/coding+(1).svg",
        },
        {
          name: "design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/color-palette.svg",
        },
      ],
    },
    {
      title: `Senior Executive Learning Module`,
      slug: "cbs-demo",
      short_description: `One module from an 18-week course design.`,
      url: `https://dazzling-centaur-f3cdf4.netlify.app/`,
      background: {
        text: ["During my time at Emeritus, I worked with faculty to develop programs for people around the world who wanted to upskill or change careers. My first assignment was to create an 18-week course for senior executives who want to transition into a Chief Financial Officer role. Participants were expected to spend 3-5 hours per week on the course, so I had to ensure that the content they worked through was highly relevant, impactful, and concise."],
        example: [],
      },
      solution: {
        text:  ["I created the design for this entire course, from developing learning outcomes, crafting learning activities, and working with the faculty to develop the lectures and other video content. For this module, I supported the faculty in developing interview questions and coaching the talent during remote filming. I also managed an additional subject matter expert who reviewed the content.", "Review the mock-up I recreated of this learning experience. In this module, the faculty interviewed six CFOs about their experiences with mergers, acquisitions, and integrations. Note that videos and learning activities are not active."],
        example: [
          {
            url: "https://lars-prof-site.s3.us-east-2.amazonaws.com/Screenshot+2023-07-11+at+11.07.43+AM.png",
            alt: "home page",
            type: "image",
            title: "course hompage"
          },
        ],
      },
  
      skills: [
        "learning design",
        "HTML", 
        "CSS", 
        "video production",
        "stakeholder management"
      ],
      tags: [
        {
          name: "learning design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/backpack.svg",
        },
        {
          name: "design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/color-palette.svg",
        },
      ],
    },
   
    {
      title: `Collaborative Learning Online`,
      slug: "collab-presentation",
      short_description: `A presentation I gave about promoting collaborative learning in an online environment.`,
      url: ``,
      background: {
        text: [`I was asked to present on a topic I am passionate about related to education and technology as part of a job interview and I chose collaborative online presentations. Online work is often thought of as a solitary endeavor.`, `I saw real potential for using the online space to equitably promote creative and engaging work in teams.`],
        example: [],
      },
      solution: {
        text: ["My presentation includes a pitch for the concept of collaborative online presentations and a plan for launching, promoting, and evaluating a pilot project."],
        example: [
          {
            url: "https://drive.google.com/file/d/1VuEeTdieGefnAIvl3Fzx6gbAy1DFaah5/preview",
            alt: "A presentation on collaborative learning online",
            type: "iframe",
            title: "Check out the slides I created for this presentation.",
            slug: "presentation",
          },
        ],
      },
   
      skills: [
        "learning design",
        "teaching",
        "presentations",
        "design thinking",
        "accessibility",
        "universal design for learning"
      ],
      tags: [
       
        {
          name: "learning design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/backpack.svg",
        },
      ],
    },
    
    {
      title: `Document Design`,
      slug: "document-design",
      short_description: `An example of my approach to instructional materials and job aids.`,
      url: ``,
      background: {
        text: ["Learning design often involves creating documents to help people complete a task. These documents can be very useful, but they're often boring, text-heavy, and poorly designed."],
        example: [],
      },
      solution: {
        text:  ["I created a simple document to help Simmons University faculty organize their slide decks. The end product is aligned with the Simmons brand, easy to use, and helps faculty improve the user experience of their courses."],
        example: [
          {
            url: "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/Create+agenda+slide.pdf",
            alt: "home page",
            type: "iframe",
            title: "Create Agenda Slide"
          },
        ],
      },
  
      skills: [
        "learning design",
        "graphic design"
      ],
      tags: [
        {
          name: "learning design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/backpack.svg",
        },
        {
          name: "design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/color-palette.svg",
        },
      ],
    },
    {
      title: `2-D Games`,
      slug: "games",
      short_description: `Just for fun: games built in Unity and Bitsy!`,
      url: `https://larszachary.itch.io/`,
      background: {
        text: ["I wanted to learn how to build games for learning. I've loved playing video games since I was a kid and I'm a believer in the power of curiosity, exploration, and fun in learning."],
        example: [],
      },
      solution: {
        text: ["I enrolled in a 2-D game development course and learned the fundamentals of creating compelling computer games. I built 5 games and published 3 of them on itch.io.", "I also began exploring other game engines - like Bitsy and Twine. I am very interested in games of all sizes, modalities, and speeds."],
        example: [
      
          {
            url: "https://itch.io/embed-upload/2986502?color=333333",
            slug: "bitsy",
            type: "iframe",
            title: "A Bitsy relaxation game. Press enter to begin. Navigate with arrows and press enter to interact."
          },
        ],
      },
      skills: [
        "C#",
        "user interface design",
        "storyboarding",
        "writing",
        "animation",
        "game mechanics",
      ],
      tags: [
        {
          name: "game development",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/gamepad.svg",
        },
        {
          name: "software development",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/coding+(1).svg",
        },
        {
          name: "design",
          image:
            "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/icons/color-palette.svg",
        },
      ],
    },
  ];

  return [projects];
};
